<template>
  <CardConf
    :title="$tc('appointment', 2)"
    :icon="'$appointments'"
    size="530"
    :activeted="true"
    ref="appointments"
  >
    <template>
      <v-row justify="center">
        <v-col cols="6">
          <v-dialog v-model="dialog_general" width="530">
            <template v-slot:activator="{ on }">
              <base-border-card
                :title="$t('general')"
                @card_clicked="dialog_general = true"
                :two_lines="$vuetify.breakpoint.xs ? true : false"
              >
              </base-border-card>
            </template>
            <v-card>
              <v-card-title>
                {{ $t("general") }}
              </v-card-title>

              <div class="close">
                <v-btn icon @click="dialog_general = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="6" md="6" v-if="false">
                    {{ $t("conf.token_payment") }}
                  </v-col>
                  <v-col cols="5" md="5" xl="4" class="pt-4" v-if="false">
                    <v-select
                      :items="items"
                      single-line
                      dense
                      outlined
                      :disabled="
                        $store.getters['auth/getSetting']('pm_stripe_id') ==
                          0 && false
                      "
                      hide-details
                      dosa
                      v-model="payment_appointment"
                    >
                    </v-select>
                    <v-tooltip
                      bottom
                      v-if="
                        this.$store.getters['auth/isLoggedIn'] &&
                          $store.getters['auth/getSetting']('pm_stripe_id') ==
                            0 &&
                          !$vuetify.breakpoint.smAndDown &&
                          false
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          style="position: absolute; right: 8px; top: 120px"
                          color="red"
                          @click="
                            $emit('changeTab');
                            $refs.card.dialog = false;
                          "
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span style="font-size: 12px">
                        Para poder activar el método paga y señal debe haber un
                        sistema de pago vinculado
                      </span>
                    </v-tooltip>
                    <v-menu
                      v-if="
                        this.$store.getters['auth/isLoggedIn'] &&
                          $store.getters['auth/getSetting']('pm_stripe_id') ==
                            0 &&
                          $vuetify.breakpoint.smAndDown &&
                          false
                      "
                      :close-on-content-click="true"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          style="position: absolute; right: 8px; top: 180px"
                          color="red"
                          @click="
                            $emit('changeTab');
                            $refs.card.dialog = false;
                          "
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <p class="py-3 mb-0 px-5" style="font-size: 12px">
                        Para poder activar el método paga y señal debe haber un
                        sistema de pago vinculado
                      </p></v-menu
                    >
                  </v-col>
                  <v-col cols="5" md="6">
                    {{ $t("states") }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="7" md="5" xl="4" class="pt-4">
                    <States />
                  </v-col>
                  <v-col cols="5" md="6">
                    {{ $t("policys.title") }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="7" md="5" xl="4" class="pt-4">
                    <v-select
                      :items="policy_items"
                      item-text="text"
                      item-value="value"
                      v-model="policy_appointment"
                      outlined
                      dense
                      hide-details
                      :disabled="disabled"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="
                      $store.getters['auth/getSetting']('policy_appointment') ==
                        'link'
                    "
                    cols="12"
                    md="12"
                  >
                    <label class="fake_label">{{
                      $t("policys.link_title")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      v-model="link"
                      type="url"
                      @blur="linked(link)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="login_error white--text rounded-xl"
                    v-if="
                      $store.getters['auth/getSetting']('policy_appointment') ==
                        'link' && !isUrl
                    "
                  >
                    Introduce un enlace correcto. <br />Ejemplo:
                    https://app.quickink.es/
                  </v-col>
                  <v-col
                    v-if="
                      $store.getters['auth/getSetting']('policy_appointment') ==
                        'page'
                    "
                    cols="11"
                    md="5"
                    :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'"
                  >
                    <PolicyEditor @validate="pagePolicy"></PolicyEditor>
                  </v-col>
                  <v-col cols="1" class="px-0">
                    <v-tooltip
                      bottom
                      v-if="
                        this.$store.getters['auth/isLoggedIn'] &&
                          !$vuetify.breakpoint.smAndDown
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="red"
                          @click="
                            $emit('changeTab');
                            $refs.card.dialog = false;
                          "
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <p class="py-0 mb-0" style="font-size: 12px">
                        Adjunta un link o la información sobre políticas,
                        términos y condiciones de tu estudio.
                      </p>
                      <p class="py-0 mb-0" style="font-size: 12px">
                        Esta información es obligatoria que la acepte el artista
                        en el proceso de invitación y el cliente final durante
                        el proceso de cita.
                      </p>
                    </v-tooltip>
                    <v-menu
                      v-if="
                        this.$store.getters['auth/isLoggedIn'] &&
                          $vuetify.breakpoint.smAndDown
                      "
                      :close-on-content-click="true"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="red"
                          @click="
                            $emit('changeTab');
                            $refs.card.dialog = false;
                          "
                          >mdi-information-outline</v-icon
                        >
                      </template>

                      <p class="py-0 mb-0 px-5 pt-2" style="font-size: 12px">
                        Adjunta un link o la información sobre políticas,
                        términos y condiciones de tu estudio.
                      </p>
                      <p class="py-0 mb-0 px-5 pb-2" style="font-size: 12px">
                        Esta información es obligatoria que la acepte el artista
                        en el proceso de invitación y el cliente final durante
                        el proceso de cita.
                      </p></v-menu
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog v-model="dialog_appointment_list" width="530">
            <template v-slot:activator="{ on }">
              <base-border-card
                :title="$t('appointment_list')"
                @card_clicked="dialog_appointment_list = true"
              >
              </base-border-card>
            </template>
            <v-card>
              <v-card-title>
                {{ $t("appointment_list") }}
              </v-card-title>

              <div class="close">
                <v-btn icon @click="dialog_appointment_list = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="5" md="6">
                    {{ $t("tattooers.reservation_system") }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="7" md="5" xl="4" class="pt-4">
                    <LaborExange></LaborExange>
                  </v-col>
                  <v-col cols="5" md="6">{{ $t("studios.formulario") }}</v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="7" md="5" xl="4" class="pt-4">
                    <v-dialog v-model="dialog_form" width="500">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          style="height: 30px; magin: 5px 0"
                          elevation="0"
                          block
                          v-on="on"
                        >
                          {{ $t("accounts.configuration") }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          style="
                            text-decoration: underline;
                            text-transform: uppercase;
                          "
                        >
                          {{ $t("studios.formulario") }}
                        </v-card-title>
                        <div class="close">
                          <v-btn icon @click="dialog_form = false" small>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="7" class="py-1">
                              {{ $t("requested_info") }}
                            </v-col>
                            <v-col cols="12" md="5" class="py-1">
                              <ClientInfo />
                            </v-col>
                            <v-col cols="12" md="7" class="py-1">
                              {{ $t("studios.preview.image") }}
                            </v-col>
                            <v-col cols="12" md="5" class="py-1">
                              <v-btn
                                height="30px"
                                elevation="0"
                                block
                                v-text="$t('edit', { name: '' })"
                                @click="$router.push({ name: 'Preview' })"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12">
                    <label>Link citas</label>
                    <v-text-field
                      outlined
                      dense
                      id="URL"
                      readonly
                      v-model="URL"
                    >
                      <template slot="prepend-inner">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" @click="copy()">
                              mdi-content-copy
                            </v-icon>
                          </template>
                          {{ $t("copy") }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!---->
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AppointmentCard",
  components: {
    CardConf: () => import("@/components/user/settings/Card"),
    LaborExange: () =>
      import(
        "@/components/user/settings/configuracion/appointments/LaborExangeDialog"
      ),
    States: () =>
      import("@/components/user/settings/configuracion/appointments/States"),
    PolicyLink: () => import("@/components/user/settings/profile/PolicyLink"),
    PolicyEditor: () =>
      import("@/components/user/settings/profile/PolicyEditor"),
    Marketplaces: () =>
      import("@/components/user/settings/configuracion/Marketplaces"),
    ClientInfo: () =>
      import(
        "@/components/user/settings/configuracion/appointments/ClientInfo"
      ),
  },

  methods: {
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      //this.dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
    linked(value) {
      console.log(this.link);
      this.settingUpdate({ key: "link_appointment", value }).then((data) => {
        this.$alert(this.$t("activated"));
      });
    },
    pagePolicy(value) {
      console.log(value);
      this.settingUpdate({ key: "page_policy_appointment", value }).then(
        (data) => {
          this.$alert(this.$t("activated"));
        }
      );
    },
    activeLink() {
      this.link = this.$store.getters["auth/getSetting"]("link_appointment");
    },
    select(type) {
      this.internalForm({
        studio_id: this.$store.state.auth.user.studio.id,
        type,
      }).then((response) => {
        if (response.success)
          this.$router.push({
            name: "appointmentCreatingView",
            params: { id: response.id },
          });
      });
    },
  },
  mounted() {
    this.activeLink();
    this.$refs.appointments.dialog = this.$route.params.system ? true : false;
  },
  data() {
    return {
      disabled: false,
      services: [],
      link: null,
      dialog_general: false,
      dialog_services: false,
      dialog_appointment_list: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      itemsServices: [],
      dialog_form: false,
      policy_items: [
        { text: this.$t("policys.link"), value: "link" },
        { text: this.$t("policys.editor"), value: "page" },
      ],
    };
  },
  computed: {
    URL() {
      if (this.$store.getters["auth/isTattooer"])
        return `${window.location.origin}/?#/inscription/tattooer/${this.$store.state.auth.user.tattooer.token}/appointment/pre`;
      if (this.$store.getters["auth/isStudio"])
        return `${window.location.origin}/?#/inscription/studio/${this.$store.state.auth.user.studio.token}/appointment/pre`;
    },
    isUrl() {
      let pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/; // fragment locator
      return !!pattern.test(this.link);
    },
    payment_appointment: {
      get() {
        return this.$store.getters["auth/getSetting"]("payment_appointment");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        if (value === 0) {
          this.$confirm(this.$t("desactivate_payment_appointment")).then(() => {
            this.settingUpdate({ key: "payment_appointment", value }).then(
              (data) => {
                this.$alert(
                  this.$t("alert_payment_appointment_disabled"),
                  "",
                  ""
                );
              }
            );
          });
          return;
        }
        this.settingUpdate({ key: "payment_appointment", value }).then(
          (data) => {
            this.$confirm(this.$t("alert_payment_appointment"), "", "").then(
              (x) => {
                this.$router.push({ name: "tattooerList" });
              }
            );
          }
        );
      },
    },
    policy_appointment: {
      get() {
        return this.$store.getters["auth/getSetting"]("policy_appointment");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        if (value === "link") this.activeLink();
        this.settingUpdate({
          key: "policy_appointment",
          value,
        }).then((data) => {});
      },
    },
    link_appointment: {
      get() {
        console.log(this.$store.getters["auth/getSetting"]("link_appointment"));
        return this.$store.getters["auth/getSetting"]("link_appointment");
      },
    },
  },
};
</script>

<style lang="sass">


.fake_label
  font-size: 14px !important
  height: 30px

@media (max-width: 959px)
  .fake_label
    font-size: 12px !important
</style>
