var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardConf',{ref:"appointments",attrs:{"title":_vm.$tc('appointment', 2),"icon":'$appointments',"size":"530","activeted":true}},[[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"width":"530"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-border-card',{attrs:{"title":_vm.$t('general'),"two_lines":_vm.$vuetify.breakpoint.xs ? true : false},on:{"card_clicked":function($event){_vm.dialog_general = true}}})]}}]),model:{value:(_vm.dialog_general),callback:function ($$v) {_vm.dialog_general=$$v},expression:"dialog_general"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("general"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog_general = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[(false)?_c('v-col',{attrs:{"cols":"6","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("conf.token_payment"))+" ")]):_vm._e(),(false)?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"5","md":"5","xl":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"single-line":"","dense":"","outlined":"","disabled":_vm.$store.getters['auth/getSetting']('pm_stripe_id') ==
                        0 && false,"hide-details":"","dosa":""},model:{value:(_vm.payment_appointment),callback:function ($$v) {_vm.payment_appointment=$$v},expression:"payment_appointment"}}),(
                      this.$store.getters['auth/isLoggedIn'] &&
                        _vm.$store.getters['auth/getSetting']('pm_stripe_id') ==
                          0 &&
                        !_vm.$vuetify.breakpoint.smAndDown &&
                        false
                    )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","right":"8px","top":"120px"},attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                          _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,170205208)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Para poder activar el método paga y señal debe haber un sistema de pago vinculado ")])]):_vm._e(),(
                      this.$store.getters['auth/isLoggedIn'] &&
                        _vm.$store.getters['auth/getSetting']('pm_stripe_id') ==
                          0 &&
                        _vm.$vuetify.breakpoint.smAndDown &&
                        false
                    )?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","right":"8px","top":"180px"},attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                          _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2484884242)},[_c('p',{staticClass:"py-3 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_vm._v(" Para poder activar el método paga y señal debe haber un sistema de pago vinculado ")])]):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"5","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("states"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"7","md":"5","xl":"4"}},[_c('States')],1),_c('v-col',{attrs:{"cols":"5","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("policys.title"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"7","md":"5","xl":"4"}},[_c('v-select',{attrs:{"items":_vm.policy_items,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":"","disabled":_vm.disabled},model:{value:(_vm.policy_appointment),callback:function ($$v) {_vm.policy_appointment=$$v},expression:"policy_appointment"}})],1),(
                    _vm.$store.getters['auth/getSetting']('policy_appointment') ==
                      'link'
                  )?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"fake_label"},[_vm._v(_vm._s(_vm.$t("policys.link_title")))]),_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","type":"url"},on:{"blur":function($event){return _vm.linked(_vm.link)}},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1):_vm._e(),(
                    _vm.$store.getters['auth/getSetting']('policy_appointment') ==
                      'link' && !_vm.isUrl
                  )?_c('v-col',{staticClass:"login_error white--text rounded-xl",attrs:{"cols":"12"}},[_vm._v(" Introduce un enlace correcto. "),_c('br'),_vm._v("Ejemplo: https://app.quickink.es/ ")]):_vm._e(),(
                    _vm.$store.getters['auth/getSetting']('policy_appointment') ==
                      'page'
                  )?_c('v-col',{attrs:{"cols":"11","md":"5","align":_vm.$vuetify.breakpoint.mdAndUp ? 'right' : 'center'}},[_c('PolicyEditor',{on:{"validate":_vm.pagePolicy}})],1):_vm._e(),_c('v-col',{staticClass:"px-0",attrs:{"cols":"1"}},[(
                      this.$store.getters['auth/isLoggedIn'] &&
                        !_vm.$vuetify.breakpoint.smAndDown
                    )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                          _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2228425043)},[_c('p',{staticClass:"py-0 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" Adjunta un link o la información sobre políticas, términos y condiciones de tu estudio. ")]),_c('p',{staticClass:"py-0 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" Esta información es obligatoria que la acepte el artista en el proceso de invitación y el cliente final durante el proceso de cita. ")])]):_vm._e(),(
                      this.$store.getters['auth/isLoggedIn'] &&
                        _vm.$vuetify.breakpoint.smAndDown
                    )?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                          _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2228425043)},[_c('p',{staticClass:"py-0 mb-0 px-5 pt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" Adjunta un link o la información sobre políticas, términos y condiciones de tu estudio. ")]),_c('p',{staticClass:"py-0 mb-0 px-5 pb-2",staticStyle:{"font-size":"12px"}},[_vm._v(" Esta información es obligatoria que la acepte el artista en el proceso de invitación y el cliente final durante el proceso de cita. ")])]):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{attrs:{"width":"530"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('base-border-card',{attrs:{"title":_vm.$t('appointment_list')},on:{"card_clicked":function($event){_vm.dialog_appointment_list = true}}})]}}]),model:{value:(_vm.dialog_appointment_list),callback:function ($$v) {_vm.dialog_appointment_list=$$v},expression:"dialog_appointment_list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("appointment_list"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog_appointment_list = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"7","md":"5","xl":"4"}},[_c('LaborExange')],1),_c('v-col',{attrs:{"cols":"5","md":"6"}},[_vm._v(_vm._s(_vm.$t("studios.formulario")))]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"7","md":"5","xl":"4"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"height":"30px","magin":"5px 0"},attrs:{"elevation":"0","block":""}},on),[_vm._v(" "+_vm._s(_vm.$t("accounts.configuration"))+" ")])]}}]),model:{value:(_vm.dialog_form),callback:function ($$v) {_vm.dialog_form=$$v},expression:"dialog_form"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("studios.formulario"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog_form = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("requested_info"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('ClientInfo')],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("studios.preview.image"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('v-btn',{attrs:{"height":"30px","elevation":"0","block":""},domProps:{"textContent":_vm._s(_vm.$t('edit', { name: '' }))},on:{"click":function($event){return _vm.$router.push({ name: 'Preview' })}}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Link citas")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"URL","readonly":""},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}},[_c('template',{slot:"prepend-inner"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.copy()}}},on),[_vm._v(" mdi-content-copy ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")])],1)],2)],1)],1)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }